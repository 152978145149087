/* eslint-disable no-empty */
/* eslint-disable no-empty */
<template>
  <div class="documentNotes" v-loading="documentNotesLoading">
    <div class="documentNotes__title border-bottom d-flex align-items-center">
      <div class="pr-1">
        <i class="el-icon-s-claim fs-4"></i>
      </div>
      <div>
        <h4 class="mb-0 text-dark">Document Chat</h4>
        <p class="notes-text text-muted">All Recipients can communicate here</p>
      </div>
    </div>
    <div class="documentsNotes_body_entry_wrapper">
      <div class="documentNotes_body">
        <el-scrollbar
          ref="scrollbarNote"
          wrap-style="max-height: calc( 100vh - 410px )"
        >
          <div class="scroll">
            <div
              class="notes"
              v-for="(document, index) in allDocuments"
              :key="index"
            >
              <div class="notes-avatar" v-if="document.user_id">
                <el-tag class="avatar-text color-6">{{
                  document.user_id | getUserNameAvatar
                }}</el-tag>
              </div>
              <div class="notes-avatar" v-if="document.contact_id">
                <el-tag class="avatar-text color-6">{{
                  document.contact_id | getUserNameAvatar
                }}</el-tag>
              </div>
              <div class="notes-content">
                <h5 v-if="document.user_id">
                  {{ document.user_id | getUserFullName }}
                  <span class="postTime">{{
                    document.created_at | globalDateTimeFormat
                  }}</span>
                </h5>
                <h5 v-if="document.contact_id">
                  {{ document.contact_id | getUserFullName }}
                  <span class="postTime">{{
                    document.created_at | globalDateTimeFormat
                  }}</span>
                </h5>
                <div
                  v-if="
                    selectedNote &&
                    selectedNote._id &&
                    document._id == selectedNote._id
                  "
                >
                  <el-input
                    type="textarea"
                    rows="3"
                    v-model="document.text"
                  ></el-input>
                  <el-button type="primary" @click="updateDocumentNotes"
                    >Update</el-button
                  >
                </div>
                <p
                  class="notes-text"
                  @dblclick="handleNoteDblClick(document)"
                  v-else
                >
                  {{ document.text }}
                </p>
              </div>
            </div>
            <!-- <div class="notes">
            <div class="notes-avatar">
              <el-tag class="avatar-text color-5">AF</el-tag>
            </div>
            <div class="notes-content">
              <h5>Alex Fominikh <span class="postTime">10:37 AM</span></h5>
              <p class="notes-text">
                <span>@suresh</span> here the documents to be filled to be
              </p>
            </div>
          </div>
          <div class="notes">
            <div class="notes-avatar">
              <el-tag class="avatar-text color-1">AF</el-tag>
            </div>
            <div class="notes-content">
              <h5>Alex Fominikh <span class="postTime">10:37 AM</span></h5>
              <p class="notes-text">
                <span>@suresh</span> here the documents to be filled to be
              </p>
            </div>
          </div>
          <div class="notes">
            <div class="notes-avatar">
              <el-tag class="avatar-text color-2">AF</el-tag>
            </div>
            <div class="notes-content">
              <h5>Elena Buzila <span class="postTime">10:37 AM</span></h5>
              <p class="notes-text">
                I have complete the process &amp; Signature as per given
                <span> @Alex Fominikh</span>
              </p>
            </div>
          </div>
          <div class="notes">
            <div class="notes-avatar">
              <el-tag class="avatar-text color-3">AF</el-tag>
            </div>
            <div class="notes-content">
              <h5>Maria Soloveva <span class="postTime">10:37 AM</span></h5>
              <p class="notes-text">Me too</p>
            </div>
          </div>
          <div class="notes">
            <div class="notes-avatar">
              <el-tag class="avatar-text color-4">AF</el-tag>
            </div>
            <div class="notes-content">
              <h5>Maria Soloveva <span class="postTime">10:37 AM</span></h5>
              <p class="notes-text">
                Let’s create something) It’s really difficult to find certain
                message when you see so many links
              </p>
            </div>
          </div>
          <div class="notes">
            <div class="notes-avatar">
              <el-tag class="avatar-text color-6">AF</el-tag>
            </div>
            <div class="notes-content">
              <h5>Alex Fominikh <span class="postTime">10:37 AM</span></h5>
              <p class="notes-text">
                <span>@suresh</span> here the documents to be filled to be
              </p>
            </div>
          </div>
          <div class="notes">
            <div class="notes-avatar">
              <el-tag class="avatar-text color-5">AF</el-tag>
            </div>
            <div class="notes-content">
              <h5>Alex Fominikh <span class="postTime">10:37 AM</span></h5>
              <p class="notes-text">
                <span>@suresh</span> here the documents to be filled to be
              </p>
            </div>
          </div>
          <div class="notes">
            <div class="notes-avatar">
              <el-tag class="avatar-text color-1">AF</el-tag>
            </div>
            <div class="notes-content">
              <h5>Alex Fominikh <span class="postTime">10:37 AM</span></h5>
              <p class="notes-text">
                <span>@suresh</span> here the documents to be filled to be
              </p>
            </div>
          </div>
          <div class="notes">
            <div class="notes-avatar">
              <el-tag class="avatar-text color-2">AF</el-tag>
            </div>
            <div class="notes-content">
              <h5>Elena Buzila <span class="postTime">10:37 AM</span></h5>
              <p class="notes-text">
                I have complete the process &amp; Signature as per given
                <span> @Alex Fominikh</span>
              </p>
            </div>
          </div>
          <div class="notes">
            <div class="notes-avatar">
              <el-tag class="avatar-text color-3">AF</el-tag>
            </div>
            <div class="notes-content">
              <h5>Maria Soloveva <span class="postTime">10:37 AM</span></h5>
              <p class="notes-text">Me too</p>
            </div>
          </div>
          <div class="notes">
            <div class="notes-avatar">
              <el-tag class="avatar-text color-4">AF</el-tag>
            </div>
            <div class="notes-content">
              <h5>Maria Soloveva <span class="postTime">10:37 AM</span></h5>
              <p class="notes-text">
                Let’s create something) It’s really difficult to find certain
                message when you see so many links
              </p>
            </div>
          </div> -->
          </div>
        </el-scrollbar>
      </div>
      <div class="documentNotes_entry">
        <el-form :model="documentNotesEntry" class="inline-entry-field">
          <el-form-item class="mb-0">
            <el-input
              type="textarea"
              :rows="getIsMobile ? '2' : '4' "
              v-model="documentNotesEntry.notesTxt"
              placeholder="Enter comments here..."
            ></el-input>
          </el-form-item>
          <el-form-item class="addDocumentNotes-wrapper">
            <el-button
              type="danger"
              class="fs-7 sendNote__Btn"
              @click="addDocumentNotes"
            >
              Post
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/config/axios";
import { mapGetters } from "vuex";
export default {
  name: "EmpDoc-DocumentNotes",
  props: ["current_user"],
  data() {
    return {
      documentNotesLoading: false,
      documentNotesEntry: {
        notesTxt: "",
      },
      allDocuments: [],
      selectedNote: null,
    };
  },
  computed: {
    ...mapGetters("documentNotes", [
      "getAllDocumentNotes",
      "getDocumentNotesAddingStatus",
      "getDocumentNotesUpdateStatus",
    ]),
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
  },
  async mounted() {
    await this.getDocumentNotes();
    // setInterval(this.getDocumentNotesInterval, 15000);
    this.scrollToEnd();
  },
  methods: {
    handleNoteDblClick(document) {
      // console.log(">>> this.current_user", this.current_user());
      // console.log(">>> document", document);
      var user_id_from_doc = "";
      try {
        user_id_from_doc = document.user_id._id;
        // eslint-disable-next-line no-empty
      } catch (error) {}

      if (user_id_from_doc == "") {
        try {
          user_id_from_doc = document.contact_id._id;
          // eslint-disable-next-line no-empty
        } catch (error) {}
      }

      var user_id_from_parent = "";
      try {
        user_id_from_parent = this.current_user().user_id._id;
        // eslint-disable-next-line no-empty
      } catch (error) {}

      if (user_id_from_parent == "") {
        try {
          user_id_from_parent = this.current_user().contact._id;
          // eslint-disable-next-line no-empty
        } catch (error) {}
      }

      if (user_id_from_doc !== "" && user_id_from_parent == user_id_from_doc) {
        this.selectedNote = document;
      }
    },
    scrollToEnd() {
      if(this.$refs.scrollbarNote){
        let scrollbarEl = this.$refs.scrollbarNote.wrap;
        scrollbarEl.scrollTop = scrollbarEl.scrollHeight
      }
      // scrollbarEl.onscroll = () => {
      //   if(scrollbarEl.scrollTop > 135) {
      //     this.isFixedTop = true
      //   } else {
      //     this.isFixedTop = false
      //   }
      // }
    },
    handleCommand(command) {
      this.$message("click on item " + command);
    },
    async getDocumentNotesInterval() {
      // var el = document.getElementById('tab-1');
      // if (el.classList.contains('el-tabs__item') && el.classList.contains('is-active')) {
      //   console.log('>>> getDocumentNotesInterval(), notes tab has class - is-active');

      let params = {
        employee_document_id: this.$route.params.employee_document_id,
      };
      try {
        const response = await axios.get(
          `/documents/${params.employee_document_id}/notes`,
          { params }
        );
        // console.log('>>> this.allDocuments.length', this.allDocuments.length);
        // console.log('>>> response.data.data.length', response.data.data.length, response.data.data, response);
        if (
          response.data.data.length > 0 &&
          this.allDocuments.length !== response.data.data.length
        ) {
          // notes updated
          this.allDocuments = response.data.data;
          // console.log('>>> updated the notes!');

          // var elementApp = document.querySelector(".documentsNotes_body_entry_wrapper .documentNotes_body");
          // console.log('>>> elementApp.scrollHeight, elementApp.height', elementApp, elementApp.scrollHeight, elementApp.clientHeight);
          // // elementApp.scrollTop = elementApp.scrollHeight + 200;
          // elementApp.scrollTop = 0;//elementApp.scrollHeight;
        }
      } catch (err) {
        console.log(">>> getDocumentNotesInverval(), error: ", err);
      }
      // }
    },
    async getDocumentNotes() {
      try {
        this.documentNotesLoading = true;
        let params = {
          employee_document_id: this.$route.params.employee_document_id,
        };
        await this.$store.dispatch(
          "documentNotes/fetchAllDocumentNotes",
          params
        );
        if (this.getAllDocumentNotes && this.getAllDocumentNotes.data) {
          this.allDocuments = this.getAllDocumentNotes.data;
        }
        this.documentNotesLoading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async addDocumentNotes() {
      try {
        this.documentNotesLoading = true;

        await this.$store.dispatch("documentNotes/addDocumentNotes", {
          text: this.documentNotesEntry.notesTxt,
          employee_document_id: this.$route.params.employee_document_id,
        });

        if (this.getDocumentNotesAddingStatus) {
          this.$notify.success({
            title: "Success",
            message: "Chats added successfully",
          });
          this.documentNotesEntry.notesTxt = "";
          await this.getDocumentNotes();
        }
        this.documentNotesLoading = false;
      } catch (err) {
        console.log(err);
      }
      this.scrollToEnd();
    },
    async updateDocumentNotes() {
      try {
        this.documentNotesLoading = true;
        // console.log("hi");
        // console.log(this.selectedNote);
        await this.$store.dispatch("documentNotes/updateDocumentNote", {
          data: {
            text: this.selectedNote.text,
          },
          note_id: this.selectedNote._id,
          employee_document_id: this.$route.params.employee_document_id,
        });

        if (this.getDocumentNotesUpdateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Chats added successfully",
          });
          this.selectedNote = null;
          await this.getDocumentNotes();
        }
        this.documentNotesLoading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async deleteDocumentNote(note) {
      console.log(note);
      //
    },
  },
  beforeDestroy() {
    this.$store.commit("documentNotes/setAllDocumentNotes",null,{root:true})
    this.$store.commit("documentNotes/setDocumentNotesAddingStatus",null,{root:true})
    this.$store.commit("documentNotes/setDocumentNotesUpdateStatus",null,{root:true})
  }
};
</script>

<style lang="scss" scoped>
.el-textarea__inner {
  // max-height: 270px;
}
.documentsNotes_body_entry_wrapper {
  // max-height: calc(100vh - 100px);
  // overflow-y: auto;
  @media (max-width: 991.98px) {
    // max-height: calc(100vh - 321px);
  }
}
.documentNotes {
  display: flex;
  flex-direction: column;
  .documentNotes__title {
    padding: 10px 15px;
  }
  .documentNotes_body {
    .notes {
      display: grid;
      grid-template-columns: 45px 1fr;
      padding: 12px 10px 12px;
      overflow-y: hidden !important;
      &:last-child {
        padding-bottom: 12px;
      }
      .notes-avatar {
        .avatar-text {
          height: 35px;
          width: 35px;
          font-weight: 600;
          font-size: 1.1em;
          line-height: 35px;
          text-align: center;
          padding: 0;
          border-radius: 30px;
          background-color: #ffffff;
        }
      }
      .notes-content {
        position: relative;
        h5 {
          font-size: 1.05em;
          color: #444444;
          font-weight: 600;
          line-height: 18px;
          margin-bottom: 0.15em;
          .postTime {
            font-size: 12px;
            font-weight: 400;
            color: #aaaaaa;
          }
        }
        .notes-text {
          font-size: 1em;
          line-height: 1.5;
          word-break: break-word;
          span {
            color: #2e4eff;
          }
        }
      }
      .color-1 {
        border: 1.5px solid #f2994a;
        color: #f2994a;
      }
      .color-2 {
        border: 1.5px solid #2d9cdb;
        color: #2d9cdb;
      }

      .color-3 {
        border: 1.5px solid #bb6bd9;
        color: #bb6bd9;
      }
      .color-4 {
        border: 1.5px solid #27ae60;
        color: #27ae60;
      }
      .color-5 {
        border: 1.5px solid #1f22ec;
        color: #1f22ec;
      }
      .color-6 {
        border: 1.5px solid #1d6648;
        color: #1d6648;
      }
      .color-7 {
        border: 1.5px solid #e20215;
        color: #e20215;
      }
      .color-8 {
        border: 1.5px solid #ff008c;
        color: #ff008c;
      }
    }
    // max-height: calc(100vh - 488px);
  }
  .documentNotes_entry {
  }
}
</style>
<style lang="scss">
.el-dropdown-menu {
  padding: 0;
  &:hover,
  &:focus {
    outline: none;
  }
}
.el-dropdown-menu__item {
  padding: 0 15px;
  line-height: 30px;
}
.documentNotes {
  max-width: 307px;
  .documentNotes_body {
    max-height: calc(100vh - 410px) !important;
    .notess-option {
      position: absolute;
      right: 0px;
      top: 0px;
      line-height: 1;
      padding: 2px;
      cursor: pointer;
      color: #666666;
      &:hover,
      &:focus {
        color: #181818;
      }
    }
    .el-icon-arrow-down {
      font-size: 12px;
    }
    .el-scrollbar {
      .el-scrollbar__wrap {
        overflow-y: auto;
        max-height: calc(100vh - 270px);
      }
    }
  }
  .documentNotes_entry {
    // position: relative;
    // height: 110px;
    .inline-entry-field {
      // position: absolute;
      // top: 0px;
      // left: 0;
      // right: 0;
      margin: 2px 2px 0;
    }
    .sendNote__Btn {
      position: absolute;
      bottom: 0;
      right: 5px;
      z-index: 100;
      padding: 5px 12px;
      font-size: 14px !important;
      margin-bottom: 2px;
      margin-right: -3px;
    }
    .addDocumentNotes-wrapper {
      margin-bottom: 0px !important;
    }
  }
}
#pane-info {
  .documentNotes {
    max-width: 307px;
    .documentNotes_body {
      max-height: none !important;
    }
  }
}
</style>
